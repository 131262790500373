import React from "react";
import { firestore, auth } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import { Link } from "react-router-dom";
import LoggedInHOC from "./LoggedInHOC";

class Dashboard extends React.Component {
    static contextType = UserContext;

    constructor() {
        super();

        this.state = {
            isLoading: true,
            forms: []
        };
    }

    componentDidMount() {
        this.loadForms(auth.currentUser.uid);
    }

    loadForms = (userId) => {
        firestore.collection("Forms")
            .where('ownerId', "==", userId)
            .get()
            .then(result => {
                this.setState({
                    isLoading: false,
                    forms: result.docs
                });
            });
    }

    render() {
        return (
            <div className="container pt-4">
                <h1>Dashboard</h1>
                <div className="pt-4">
                    {
                        this.state.forms.map(form => {
                            return (
                                <div key={form.id} className="card" style={{ width: "18rem" }}>
                                    <div className="card-body">
                                        <h5 className="card-title">{form.data().title}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">{form.data().domain}</h6>
                                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                        <Link to={"/form/" + form.id} className="card-link">Edit</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default LoggedInHOC(Dashboard);