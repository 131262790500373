import React from "react";
import { auth } from "../firebase";
import LoginForm from "./LoginForm";

function LoggedInHOC(WrappedComponent) {
    return class extends React.Component {
        constructor() {
            super();

            this.state = {
                currentUser: auth.currentUser,
                listener: null
            };
        }

        componentDidMount() {
            var listener = auth.onAuthStateChanged(user => {
                this.setState({
                    currentUser: user
                });
            });

            this.setState({
                listener: listener
            });
        }

        componentWillUnmount(){
            this.state.listener && this.state.listener();
        }

        render() {
            if (this.state.currentUser) {
                return <WrappedComponent {...this.props} />;
            } else {
                return <LoginForm />
            }
        }
    }
}

export default LoggedInHOC;