import React from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";
import { auth } from "../firebase";
import Axios from "axios";

class NavBar extends React.Component {

    static contextType = UserContext;

    logout = () => {
        auth.signOut().then(() => {
            window.location = "/";
        });
    }

    openBillingPortal = async () => {
        const returnUrl = window.location.href;
        const token = await auth.currentUser.getIdToken(true);
        const response = await Axios.post("/api/getBillingPortalUrl", { returnUrl: returnUrl }, { headers: { Authorization: `Bearer ${token}` } });

        window.location = response.data.portalUrl;
    }

    render() {
        const user = this.context;

        return (
            <nav className="navbar navbar-expand-lg sticky-top navbar-dark bg-primary">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="/logo.png" style={{ marginTop: "-5px" }} className="logo mr-2" alt="EmailSender Logo" height={27} />EmailSender
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <ScrollLink className="nav-link"
                                    activeClass="active"
                                    href="#features"
                                    to="features"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}>Features</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link"
                                    activeClass="active"
                                    href="#pricing"
                                    to="pricing"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}>Pricing</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link"
                                    activeClass="active"
                                    href="#demo"
                                    to="demo"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}>Demo</ScrollLink>
                            </li>
                        </ul>

                        <ul className="navbar-nav">
                            {user && (
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        My Account
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                                        <button className="dropdown-item" onClick={this.openBillingPortal}>Billing</button>
                                        <div className="dropdown-divider"></div>

                                        <form className="form-inline">
                                            <button onClick={this.logout} className="dropdown-item btn bg-white m-1" type="button">Logout</button>
                                        </form>
                                    </div>
                                </li>
                            )}
                            <li className="nav-item">
                                <form className="form-inline">
                                    {!user && (
                                        <>
                                            <Link to="/login" className="btn bg-white m-1">Login</Link>
                                            <button className="btn bg-white m-1" type="button">Signup</button>
                                        </>
                                    )}
                                </form>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;