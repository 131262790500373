import React from "react";

export default function PlanCard(props) {
    var button = <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>;

    if(props.isLoggedIn){
        button = props.isCurrent
        ? <button type="button" disabled className="btn btn-lg btn-block btn-disabled">Current Plan</button>
        : <button disabled={props.isDisabled} onClick={() => props.onSwitchClick()} type="button" className="btn btn-lg btn-block btn-primary">Switch Plan</button>;
    }

    return (
        <div className="card mb-4 box-shadow">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">{props.name}</h4>
            </div>
            <div className="card-body">
                <h1 className="card-title pricing-card-title">${props.price} <small className="text-muted">/ mo</small></h1>
                <ul className="list-unstyled mt-3 mb-4">
                    <li>Unlimited forms</li>
                    <li>10 GB of storage</li>
                    <li>Priority email support</li>
                    <li>Help center access</li>
                </ul>
                {button}
            </div>
        </div>
    )
}