import React from "react";

export default function TestPage() {
    const formId = "zPG1oZSQwj93oVHTX1TL";
    const postUrl = window.location.origin + "/api/submitForm/" + formId;

    return (
        <div className="container pt-4">
            <h1>Test Form</h1>
            <form action={postUrl} method="POST">
                <div className="form-group">
                    <label for="txtTestEmail">Email:</label>
                    <input className="form-control" id="txtTestEmail" type="email" name="email" required />
                </div>
                <div className="form-group">
                    <label for="txtTestSubject">Subject:</label>
                    <input className="form-control" id="txtTestSubject" type="text" name="subject" required />
                </div>
                <div className="form-group">
                    <label for="txtTestHoneypot">Honeypot:</label>
                    <input className="form-control" id="txtTestHoneypot" type="text" name="h-subject" />
                </div>
                <div className="form-group">
                    <label for="txtTestMessage">Message:</label>
                    <textarea className="form-control" id="txtTestMessage" name="message" required ></textarea>
                </div>
                <button className="btn btn-primary" type="submit">Send</button>
            </form>
        </div>
    )
}