import React, { useContext } from "react";
import { auth } from "../firebase";

class LoginForm extends React.Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: ""
        };
    }

    handleEmailChange = (newEmail) => {
        this.setState({ email: newEmail });
    }

    handlePasswordCHange = (newPassword) => {
        this.setState({ password: newPassword });
    }

    handleLogin = ev => {
        ev.preventDefault();

        auth.signInWithEmailAndPassword(this.state.email, this.state.password).then(user => {
            window.location = "/dashboard";
        });
    }

    render() {
        return (
            <div className="container mt-5">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-4">
                        <h1>Login</h1>
                        <form onSubmit={this.handleLogin}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input value={this.state.email} onChange={ev => this.handleEmailChange(ev.target.value)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <input value={this.state.password} onChange={ev => this.handlePasswordCHange(ev.target.value)} type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                            <button type="submit" className="btn btn-primary">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginForm;