import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.css";

import * as Icon from 'react-bootstrap-icons';

export default function NotFound() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.errorTemplate}>
                        <h1>
                            Oops!</h1>
                        <h2>
                            404 Not Found</h2>
                        <div className={styles.errorDetails}>
                            Sorry, an error has occured, Requested page not found!
                </div>
                        <div className={styles.errorActions}>
                            <Link to="/" className="btn btn-primary btn-lg" style={{verticalAlign: "middle"}}>
                                <Icon.HouseFill />&nbsp;Take Me Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}