import React, { useEffect, useState } from "react";
import { firestore, auth } from "../firebase";
import { useParams } from "react-router-dom";
import LoadingIcon from "./LoadingIcon";
import LoggedInHOC from "./LoggedInHOC";
import CustomRelativeTime from "./CustomRelativeTime";

function FormDetail() {
    let { formId } = useParams();
    let [formDetails, setFormDetails] = useState(null);
    let [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        firestore.collection("Forms")
            .doc(formId)
            .get()
            .then(formDetails => {
                setFormDetails(formDetails.data());
            });

        firestore.collection("submissions")
            .where('ownerId', "==", auth.currentUser.uid)
            .where('formId', "==", formId)
            .orderBy('createdAt', "desc")
            .get()
            .then(result => {
                setSubmissions(result.docs);
            })
    }, [formId]);

    return (
        <div className="container pt-4">
            <LoadingIcon isLoading={!formDetails} />
            {formDetails && (
                <>
                    <h1>{formDetails.title}</h1>
                    <div>Domain: {formDetails.domain}</div>
                </>
            )}

            <div className="mt-4">
                {submissions.map(submission => (
                <div key={submission.id} className="card mb-3">
                        <div className="card-header">
                            <span className="align-middle">
                                Received <CustomRelativeTime value={submission.data().createdAt.toDate()} />
                                {submission.data().isSent ? <span className="ml-2 badge badge-info">Sent</span> : <span className="ml-2 badge badge-info">Not Sent</span>}
                            </span>
                            <a href="#" className="btn btn-sm btn-warning float-right">Report as spam</a>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{submission.data().subject}</h5>
                            <p className="card-text">{submission.data().message}</p>
                        </div>
                        <div className="card-footer text-muted">
                            From: <a href={"mailto:" + submission.data().email}>{submission.data().email}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LoggedInHOC(FormDetail);