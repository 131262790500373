import React from "react";
import PlanCard from "./PlanCard";
import LoggedInHOC from "./LoggedInHOC";
import { auth } from "../firebase";
import { loadStripe } from '@stripe/stripe-js';
import Axios from "axios";

class Billing extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            stripePromise: null,
            currentPlan: null
        };
    }

    componentDidMount() {
        this.setState({
            stripePromise: loadStripe('pk_test_2erdRmaEtZb1LYYFNI73MTyt00FvZrgS0J')
        });

        auth.currentUser.getIdToken(true).then(token => {
            return Axios.get("/api/getBillingInfo", { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({
                    currentPlan: response.data.plan
                });
            });
        });
    }

    switchPlan = async (planPriceId) => {
        this.setState({
            isLoading: true
        });

        const token = await auth.currentUser.getIdToken(true);

        const response = await Axios.get("/api/createCheckout/" + planPriceId, { headers: { Authorization: `Bearer ${token}` } });

        const stripe = await this.state.stripePromise;
        const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
        });

        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    }

    openBillingPortal = async () => {
        const returnUrl = window.location.href;
        const token = await auth.currentUser.getIdToken(true);
        const response = await Axios.post("/api/getBillingPortalUrl", { returnUrl: returnUrl }, { headers: { Authorization: `Bearer ${token}` } });

        window.location = response.data.portalUrl;
    }

    render() {
        var user = auth.currentUser;

        return (
            <div className="container pt-4">
                <h1>Billing</h1>
                <button className="btn btn-primary" type="button" onClick={this.openBillingPortal}>Manage Billing</button>
                <br />
                <br />
                <div className="container">
                    <div className="card-deck mb-3 text-center">
                        <PlanCard hideButton={true} name="Free" price={0} isLoggedIn={user} isCurrent={!this.state.currentPlan} onSwitchClick={this.openBillingPortal} />
                        <PlanCard hideButton={true} isDisabled={this.state.isLoading} name="Pro" price={4.99} isLoggedIn={user} isCurrent={this.state.currentPlan === "price_HI98P5U1LbXGtR"} onSwitchClick={this.openBillingPortal} />
                        <PlanCard hideButton={true} isDisabled={this.state.isLoading} name="Enterprise" price={19.99} isLoggedIn={user} isCurrent={this.state.currentPlan === "price_HIQ5LDAG6c1Ubh"} onSwitchClick={this.openBillingPortal} />
                    </div>
                </div>
            </div>
        );
    }
}

export default LoggedInHOC(Billing);