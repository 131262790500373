import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAo5kCHmVXWlni2CNlUlfLv-fvQJuiSMs4",
    authDomain: "emailsender-b5bb3.firebaseapp.com",
    databaseURL: "https://emailsender-b5bb3.firebaseio.com",
    projectId: "emailsender-b5bb3",
    storageBucket: "emailsender-b5bb3.appspot.com",
    messagingSenderId: "496354135332",
    appId: "1:496354135332:web:0ddbc8ce32fd25217837b1"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();