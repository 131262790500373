import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import CreateForm from "./components/CreateForm";
import DemoForm from "./components/DemoForm";
import NavBar from "./components/NavBar";
import PricingTable from "./components/PricingTable";
import Features from "./components/Features";
import LoginForm from "./components/LoginForm";
import Dashboard from "./components/Dashboard";
import NotFound from "./components/NotFound";
import Billing from "./components/Billing";
import UserProvider from "./providers/UserProvider";
import FormDetail from './components/FormDetail';

import { IntlProvider } from "react-intl";
import TestPage from './components/TestPage';

class App extends React.Component {

  constructor() {
    super();

    this.state = {
      isShowingCreateForm: true,
      isShowingDemoForm: false,
      formKey: null
    };
  }

  handleFormCreated = key => {
    this.setState({
      isShowingCreateForm: false,
      isShowingDemoForm: true,
      formKey: key
    });
  }

  render() {
    return (
      <UserProvider>
        <IntlProvider locale={"en-US"}>
          <Router>
            <NavBar />
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/form/:formId" component={FormDetail} />
              <Route path="/billing" component={Billing} />
              <Route path="/login" component={LoginForm} />
              <Route path="/test" component={TestPage} />
              <Route path="/" exact={true}>
                <Features />
                <PricingTable />
                <div className="container" style={{ paddingBottom: "500px" }}>
                  <div className="jumbotron mt-5">
                    {this.state.isShowingCreateForm && <CreateForm onFormCreated={this.handleFormCreated} />}
                    {this.state.isShowingDemoForm && <DemoForm formKey={this.state.formKey} />}
                  </div>
                </div>
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Router>
        </IntlProvider>
      </UserProvider>
    );
  }
}
export default App;