import React from "react";
import axios from "axios";
import LoadingIcon from "./LoadingIcon";

class DemoForm extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            showSuccess: false,
            showError: false,
            formEmail: "",
            formSubject: "",
            formMessage: ""
        };
    }

    encodeHtml(rawStr) {
        var encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
            return '&#' + i.charCodeAt(0) + ';';
        });

        return encodedStr;
    }

    handleEmailChange = (newEmail) => {
        this.setState({ formEmail: newEmail });
    }

    handleSubjectChange = (newSubject) => {
        this.setState({ formSubject: newSubject });
    }

    handleMessageChange = (newMessage) => {
        this.setState({ formMessage: newMessage });
    }

    handleFormSubmit = ev => {
        ev.preventDefault();

        this.setState({
            isLoading: true
        });

        var rootApi = window.location.origin + "/api/";//"http://localhost:5001/emailsender-b5bb3/us-central1/";
        axios.post(rootApi + "submitFormAjax/" + this.props.formKey, {
            fromEmail: this.state.formEmail,
            subject: this.state.formSubject,
            message: this.state.formMessage
        }).then((response) => {
            this.setState({
                showSuccess: response.data.isSuccess,
                showError: !response.data.isSuccess,
                isLoading: false
            });
        })
            .catch(err => {
                this.setState({
                    showSuccess: false,
                    showError: true,
                    isLoading: false
                });
            });
    }

    render() {
        var rootApi = window.location.origin + "/api/";//"http://localhost:5001/emailsender-b5bb3/us-central1/";
        var postUrl = rootApi + "submitForm/" + this.props.formKey;
        var formHtml = this.encodeHtml(`
<form method="POST" action="{postUrl}">
    <label>Email:</label>
    <input type="email" name="email" />

    <label>Subject:</label>
    <input type="text" name="subject" />

    <label>Message:</label>
    <textarea name="message"></textarea>

    <button type="submit">Send</button>
</form>`).trim();

        formHtml = formHtml.replace("{postUrl}", "<strong>" + postUrl + "</strong>");

        return (
            <div>
                <h1>How to use it ?</h1>
                <p className="lead">
                    You can modify your contact form by setting the action attribute to: <br />
                    <code>{postUrl}</code>
                </p>
                <p className="lead">Here's an example:</p>
                <div><pre dangerouslySetInnerHTML={{ __html: formHtml }}></pre></div>
                <h1>Try it!</h1>
                <p className="lead">You can try your contact form right now. The email will be sent to <strong id="destinationEmail"></strong></p>
                <form onSubmit={this.handleFormSubmit} className="mb-2">
                    <div className="form-group">
                        <label htmlFor="txtTestEmail">Email:</label>
                        <input onChange={ev => this.handleEmailChange(ev.target.value)} value={this.state.formEmail} className="form-control" id="txtTestEmail" type="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="txtTestSubject">Subject:</label>
                        <input onChange={ev => this.handleSubjectChange(ev.target.value)} value={this.state.formSubject} className="form-control" id="txtTestSubject" type="text" name="subject" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="txtTestMessage">Message:</label>
                        <textarea onChange={ev => this.handleMessageChange(ev.target.value)} value={this.state.formMessage} className="form-control" id="txtTestMessage" name="message" required />
                    </div>
                    {!this.state.isLoading && <button className="btn btn-primary" type="submit">Send</button>}
                </form>
                <LoadingIcon isLoading={this.state.isLoading} />
                {this.state.showSuccess && (
                    <div className="alert alert-success">
                        Email sent, check your inbox!
                    </div>
                )}
                {this.state.showError && (
                    <div id="testingFailure" className="alert alert-danger">
                        An error occured :'(
                    </div>
                )}
            </div>
        )
    }
}

export default DemoForm;