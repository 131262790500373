import React from 'react';
import LoadingIcon from "./LoadingIcon";
import axios from "axios";

class CreateForm extends React.Component {
    constructor(){
        super();

        this.state = {
            destinationEmail: "",
            isLoading: false
        };
    }

    handleEmailChange = (newEmail) => {
        this.setState({
            destinationEmail: newEmail
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            isLoading: true
        });

        var rootApi = window.location.origin + "/api/";//"http://localhost:5001/emailsender-b5bb3/us-central1/";
        var url = rootApi + "addMessage";
        axios.post(url, {email: this.state.destinationEmail}).then((response) => {
            this.setState({
                isLoading: false
            });

            this.props.onFormCreated(response.data.formKey);
        })
    }

    render() {
        return (
            <form id="demo" onSubmit={this.handleSubmit}>
                <h1>Create a contact form for your static website</h1>
                <p className="lead">No need for php or costly servers, keep your website static and let us handle the rest.</p>
                <div className="form-group">
                    <label htmlFor="txtEmail">Destination email:</label>
                    <input id="txtEmail" className="form-control" type="email" name="email" value={this.state.destinationEmail} onChange={ev => this.handleEmailChange(ev.target.value)} required />
                    <small className="form-text text-muted">Where do you want to be notified when someone uses your contact form?</small>
                </div>
                <LoadingIcon isLoading={this.state.isLoading} />
                {!this.state.isLoading && <button className="btn btn-primary btn-lg" type="submit">Create contact form</button>}
            </form>
        );
    }
}

export default CreateForm;